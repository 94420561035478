'use client';
import shopifyClient from '@/lib/shopify/client';
import { useAtom, useSetAtom } from 'jotai';
import {
  cartAtom,
  shopifyCheckoutIdWithStorageAtom,
  isRemovingLineItemAtom,
  isRemovingOneItemAtom,
  isAddingOneAtom,
  isCartSlideOutOpenAtom,
} from '@/store/cartStore';
import { getProduct } from '@/sanity/services/productService';
import {
  mixpanelRemoveFromCartEvent,
  mixpanelAddToCartEvent,
} from '@/lib/mixpanel/services/client';
import { Checkout } from 'shopify-buy';
import {
  BillingFormType,
  CartType,
  DeliveryFormType,
  Product,
  UserFormType,
} from '@/types/types';
import { datadogLogs } from '@datadog/browser-logs';
import { useStatsigClient } from '@statsig/react-bindings';
import {
  soleProprietorProductSwapMap,
  soleProprietorReverseProductSwapMap,
} from '@/lib/shopify/constants';

const TAX_RATE_ZA = 15;
const DELIVERY_FEE = 150;
const IK_DEBIT_DELIVERY_FEE = 125;

const useCart = () => {
  const [cart, setCart] = useAtom(cartAtom);
  const { logEvent } = useStatsigClient();

  const [isRemovingLineItem, setIsRemovingLineItem] = useAtom(
    isRemovingLineItemAtom
  );
  const [isRemovingOneItem, setIsRemovingOneItem] = useAtom(
    isRemovingOneItemAtom
  );
  const [isAddingOneItem, setIsAddingOneItem] = useAtom(isAddingOneAtom);
  const setIsCartOpen = useSetAtom(isCartSlideOutOpenAtom);

  const [cartId, setCartIdWithStorage] = useAtom(
    shopifyCheckoutIdWithStorageAtom
  );

  const createCart = async () => {
    const shopifyCheckout = await shopifyClient.checkout.create();
    setCartIdWithStorage(shopifyCheckout.id);
    updateCart([], shopifyCheckout);
    return shopifyCheckout;
  };

  const fetchCart = async (cartParamId?: string) => {
    if (!cartId && !cartParamId) {
      await createCart();
      return;
    } else if (cartId !== cart?.id || cartParamId !== cart?.id) {
      let cartShopId = cartParamId ?? cartId;
      const shopifyCheckout = await shopifyClient.checkout.fetch(
        `${cartShopId}`
      );

      const lineItems = await fetchLineItems(shopifyCheckout?.lineItems || []);

      const lineItemsWithProduct = lineItems.filter((item) => item.product);
      const lineItemsWithoutProduct = lineItems.filter((item) => !item.product);
      if (lineItemsWithoutProduct.length > 0 && cartShopId) {
        const lineItemsToRemove = lineItemsWithoutProduct.map(
          (item) => item.id
        );
        await shopifyClient.checkout.removeLineItems(
          cartShopId,
          lineItemsToRemove
        );
      }
      updateCart(lineItemsWithProduct, shopifyCheckout);
    }
  };

  const fetchLineItems = async (lineItems: ShopifyBuy.CheckoutLineItem[]) => {
    const shopifyLineItems = lineItems.map(
      (item: ShopifyBuy.CheckoutLineItem) => ({
        productHandle: item?.variant?.product?.handle ?? '',
        variantGid: item.variant?.id,
        quantity: item.quantity,
        id: item.id,
      })
    );

    const fetchedLineItems = await Promise.all(
      shopifyLineItems.map((item) => getProduct(item.productHandle))
    );
    const response = fetchedLineItems.map((product, index) => ({
      product: {
        ...(product as Product),
        variantGid: shopifyLineItems[index].variantGid,
      },
      id: shopifyLineItems[index].id,
      quantity: shopifyLineItems[index].quantity,
    }));
    return response;
  };

  /**
   * Initializes the process of adding an item to the cart.
   *
   * @param theCartId - The ID of the cart.
   */
  const initAddToCart = async (
    theCartId: string | null,
    variantIds: string[]
  ) => {
    if (theCartId === null) return;

    setIsAddingOneItem(variantIds);

    const itemsToAdd = variantIds.map((variantId) => ({
      variantId,
      quantity: 1,
    }));

    try {
      const shopifyCheckout = await shopifyClient.checkout.addLineItems(
        theCartId,
        itemsToAdd
      );

      const lineItems = await fetchLineItems(shopifyCheckout?.lineItems || []);

      const addedLineItems = lineItems.filter(
        (item) =>
          item.product?.variantGid &&
          variantIds.includes(item.product.variantGid)
      );

      updateCart(lineItems, shopifyCheckout);
      setIsAddingOneItem(false);

      addedLineItems.forEach((item) => {
        if (item.product) {
          mixpanelAddToCartEvent(item);
          logEvent('add_to_cart', item.product.sanityTitle, {
            id: item.product.id,
            name: item.product.sanityTitle,
            price: `${item.product.price}`,
            event_source: 'Online',
            currency_code: 'ZAR',
            category: item.product.categorySlug.replace(/-/g, ' '),
          });
        }
      });
    } catch (error) {
      console.error('Error adding item to cart:', error);
      setIsAddingOneItem(false);
      throw error;
    }
  };

  const getVariantGId = (variantId: string): string => {
    const isSwappedItemInCart = cart?.lineItems.some(
      (item) =>
        soleProprietorReverseProductSwapMap[item.product.variantGid] ===
        variantId
    );
    if (isSwappedItemInCart) {
      return soleProprietorProductSwapMap[variantId] ?? variantId;
    }
    return variantId;
  };

  const addItem = async (
    variantId: string,
    clearExistingItemsFirst: boolean = false
  ) => {
    const variantGId = getVariantGId(variantId);

    try {
      if (clearExistingItemsFirst) {
        const shopifyResponse = await createCart();
        await initAddToCart(shopifyResponse.id, [variantGId]);
      } else {
        await initAddToCart(cartId, [variantGId]);
      }
    } catch (error: any) {
      //try to parse error and handle accordingly if fails bubble up error
      try {
        const errorResponse = JSON.parse(error.message);
        const field = errorResponse[0].field[0];
        const message = errorResponse[0].message;
        if (field === 'checkoutId' && message === 'Checkout does not exist') {
          const shopifyResponse = await createCart();
          await initAddToCart(shopifyResponse.id, [variantGId]);
        }
      } catch (error) {
        console.error('Error adding item to cart:', error);
        throw error;
      }
    }
  };

  const addItems = async (
    variantIds: string[],
    clearExistingItemsFirst: boolean = false
  ) => {
    const variantGIds = variantIds.map((variantId) => getVariantGId(variantId));

    try {
      if (clearExistingItemsFirst) {
        const shopifyResponse = await createCart();
        await initAddToCart(shopifyResponse.id, variantGIds);
      } else {
        await initAddToCart(cartId, variantGIds);
      }
    } catch (error: any) {
      //try to parse error and handle accordingly if fails bubble up error
      try {
        const errorResponse = JSON.parse(error.message);
        const field = errorResponse[0].field[0];
        const message = errorResponse[0].message;
        if (field === 'checkoutId' && message === 'Checkout does not exist') {
          const shopifyResponse = await createCart();
          await initAddToCart(shopifyResponse.id, variantGIds);
        }
      } catch (error) {
        console.error('Error adding item to cart:', error);
        throw error;
      }
    }
  };

  const updateItem = async (variantId: string, quantity: number) => {
    if (quantity > 0) {
      setIsAddingOneItem(variantId);
    } else {
      setIsRemovingOneItem(variantId);
    }

    if (cartId === null) {
      setIsAddingOneItem(false);
      setIsRemovingOneItem(false);
      return;
    }
    const shopifyCheckout = await shopifyClient.checkout.fetch(cartId);

    if (!shopifyCheckout?.lineItems) {
      setIsAddingOneItem(false);
      setIsRemovingOneItem(false);
      return;
    }
    const lineItem = shopifyCheckout.lineItems.find(
      (item) => item.variant && item.variant.id === variantId
    );

    if (!lineItem) {
      throw new Error('Item not found in cart');
    }

    if (quantity < 0 && lineItem.quantity === 1) {
      return;
    }

    const newQuantity = lineItem.quantity + quantity;
    const shopifyCheckoutUpdated = await shopifyClient.checkout.updateLineItems(
      cartId,
      [{ id: lineItem.id, quantity: newQuantity }]
    );

    const lineItems = await fetchLineItems(
      shopifyCheckoutUpdated?.lineItems ?? []
    );

    let mixpanelLineItem = lineItems.find(
      (item) => item.product && item.product.variantGid === variantId
    );
    if (mixpanelLineItem && mixpanelLineItem.product) {
      if (quantity > 0) {
        mixpanelAddToCartEvent(mixpanelLineItem);
        logEvent('add_to_cart', mixpanelLineItem.product.sanityTitle, {
          id: mixpanelLineItem.product.id,
          name: mixpanelLineItem.product.sanityTitle,
          price: `${mixpanelLineItem.product.price}`,
          event_source: 'Online',
          currency_code: 'ZAR',
          category: mixpanelLineItem.product.categorySlug.replace(/-/g, ' '),
        });
      } else {
        mixpanelLineItem.quantity = newQuantity;
        mixpanelRemoveFromCartEvent(mixpanelLineItem);
        logEvent('remove_from_cart', mixpanelLineItem.product.sanityTitle, {
          id: mixpanelLineItem.product.id,
          name: mixpanelLineItem.product.sanityTitle,
          price: `${mixpanelLineItem.product.price}`,
          event_source: 'Online',
          currency_code: 'ZAR',
          category: mixpanelLineItem.product.categorySlug.replace(/-/g, ' '),
        });
      }
    }

    updateCart(lineItems, shopifyCheckoutUpdated);
    setIsAddingOneItem(false);
    setIsRemovingOneItem(false);
  };

  const removeItem = async (variantId: string) => {
    if (cartId === null) return;
    setIsRemovingLineItem(variantId);
    const shopifyCheckout = await shopifyClient.checkout.fetch(cartId);

    if (!shopifyCheckout?.lineItems) {
      return;
    }

    const lineItem = shopifyCheckout.lineItems.find(
      (item) => item.variant && item.variant.id === variantId
    );

    if (!lineItem) {
      return;
    }

    const shopifyCheckoutUpdated = await shopifyClient.checkout.removeLineItems(
      cartId,
      [lineItem.id]
    );

    const lineItems = await fetchLineItems(shopifyCheckoutUpdated.lineItems);

    const removedItem = cart?.lineItems.find(
      (item) => item.product.variantGid === variantId
    );
    if (removedItem) mixpanelRemoveFromCartEvent(removedItem);

    if (lineItems.length === 0) setIsCartOpen(false);

    setIsRemovingLineItem(false);
    updateCart(lineItems, shopifyCheckoutUpdated);
  };

  const addDiscount = async (
    discountCode: string
  ): Promise<{ success: boolean; message: string }> => {
    if (cartId === null) return { success: false, message: 'Cart not found' };
    const shopifyCheckoutUpdated = await shopifyClient.checkout.addDiscount(
      cartId,
      discountCode
    );

    let message = 'Invalid discount code';

    // if error message is returned from shopify
    //@ts-ignore
    const errors = shopifyCheckoutUpdated.userErrors;
    if (errors && errors[0]?.message) {
      return {
        success: false,
        message: message,
      };
    }

    if (
      !shopifyCheckoutUpdated?.discountApplications ||
      shopifyCheckoutUpdated?.discountApplications.length === 0
    ) {
      return {
        success: false,
        message: 'Discount code requirement not met',
      };
    }

    updateCart(cart?.lineItems ?? [], shopifyCheckoutUpdated);
    return { success: true, message: 'Discount has been applied to your cart' };
  };

  const calculateTotalTax = (totalPrice: number) => {
    const originalNonVATPrice = totalPrice / (1 + TAX_RATE_ZA / 100);
    return totalPrice - originalNonVATPrice;
  };

  const productIsDebitCard = (item: {
    product: {
      slug: string;
      categorySlug: string;
    };
  }) => {
    return item?.product?.slug === 'tyme-debit-card';
  };
  const calculateDeliveryPrice = (lineItems: any[]) => {
    const hasCardMachine = lineItems.some((item) =>
      ['card-machines', 'point-of-sale'].includes(item?.product?.categorySlug)
    );

    if (hasCardMachine) return 0;

    const hasIkDebit = lineItems.some((item) => productIsDebitCard(item));

    if (hasIkDebit) return IK_DEBIT_DELIVERY_FEE;

    return DELIVERY_FEE;
  };

  const calculateDiscountPrice = (
    discountApplications: any[],
    totalPrice: number
  ) => {
    if (!discountApplications || discountApplications?.length === 0) return 0;

    const discount = discountApplications[0];
    if (discount.value?.percentage && discount.value?.percentage > 0) {
      return (discount.value.percentage * totalPrice) / 100;
    }
    return parseInt(discount.value.amount);
  };

  const updateCart = (lineItems: any[], shopifyCheckout: Checkout) => {
    const totalPrice =
      (typeof shopifyCheckout?.totalPrice?.amount === 'string'
        ? parseFloat(shopifyCheckout?.totalPrice?.amount)
        : shopifyCheckout?.totalPrice?.amount) || 0;
    const subTotalPrice = shopifyCheckout?.subtotalPrice?.amount || 0;
    const totalTax = calculateTotalTax(totalPrice);
    const deliveryPrice = calculateDeliveryPrice(lineItems);
    const discountPrice = calculateDiscountPrice(
      shopifyCheckout?.discountApplications || [],
      totalPrice
    );

    const cartData = {
      id: shopifyCheckout.id,
      lineItems,
      totalPrice: totalPrice + deliveryPrice,
      totalPriceWithoutDelivery: totalPrice,
      totalTax: totalTax,
      subtotalPrice: subTotalPrice - totalTax,
      deliveryPrice: deliveryPrice,
      discountPrice: discountPrice,
      discountCode:
        shopifyCheckout?.discountApplications.length > 0
          ? // @ts-ignore
            shopifyCheckout?.discountApplications[0].code
          : '',
      email: shopifyCheckout.email,
    };

    setCart(cartData);

    return cartData;
  };

  const updateShopifyShippingAddress = async (
    userDetails: UserFormType,
    deliveryAddress: DeliveryFormType
  ) => {
    if (cartId === null || cart === null) return;

    const shippingAddress = {
      address1: deliveryAddress.deliveryStreet,
      city: deliveryAddress.deliveryCity,
      province: deliveryAddress.deliveryProvince,
      zip: deliveryAddress.deliveryPostCode,
      country: 'South Africa',
      firstName: userDetails.fullName.split(' ')[0],
      lastName: userDetails.fullName.split(' ')[1],
      phone: userDetails.cellNumber,
    };

    const shopifyCheckoutUpdated =
      await shopifyClient.checkout.updateShippingAddress(
        cartId,
        shippingAddress
      );

    updateCart(cart.lineItems, shopifyCheckoutUpdated);
    return shopifyCheckoutUpdated;
  };

  const fetchShopifyDraftOrder = async (draftOrderId: string) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const data = {
        query: `
          query getDraftOrder($draftOrderId: ID!) {
            draftOrder(id: $draftOrderId) {
              id
              email
              totalPriceSet {
                presentmentMoney {
                  amount
                  currencyCode
                }
              }
              lineItems(first: 10) {
                edges {
                  node {
                    title
                    variant {
                      id
                      title
                      product {
                        id
                        title
                        handle
                      }
                    }
                    quantity
                    discountedTotalSet {
                      presentmentMoney {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          draftOrderId: draftOrderId,
        },
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();

      const shopifyLineItems = json.data.draftOrder.lineItems.edges.map(
        (item: any) => ({
          productHandle: item.node.variant.product.handle,
          quantity: item.node.quantity,
        })
      );

      const email = json.data.draftOrder.email;

      const fetchedLineItems = await Promise.all(
        shopifyLineItems.map((item: { productHandle: string }) =>
          getProduct(item.productHandle)
        )
      );

      const lineItems = fetchedLineItems.map((product, index) => ({
        product,
        quantity: shopifyLineItems[index].quantity,
      }));

      const totalPrice =
        json.data.draftOrder.totalPriceSet.presentmentMoney.amount;

      return { email, lineItems, totalPrice };
    } catch (error) {
      // Handle errors based on your application requirements
      datadogLogs.logger.debug('Checkout error', {
        message: error,
      });
      console.error('Error creating draft order:', error);
      throw error; // Rethrow the error if needed
    }
  };

  const createShopifyOrder = async (
    cart: CartType,
    userDetails: UserFormType,
    deliveryAddress: DeliveryFormType,
    billingAddress: BillingFormType
  ) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const orderDetails = {
        input: {
          customAttributes: { key: 'ikShopDirectOrder', value: 'true' },
          tags: ['IKHOKHA_HUBSPOT_CART_2_0'],
          lineItems: cart.lineItems.map((item) => ({
            // The tymebank card swap doesn't match the lineItemType
            // @ts-ignore
            variantId: item?.product?.variantGid ?? item.variantId,
            quantity: item.quantity,
          })),
          shippingAddress: {
            address1: deliveryAddress.deliveryStreet,
            address2: deliveryAddress.deliveryComplex,
            city: deliveryAddress.deliveryCity,
            province: deliveryAddress.deliveryProvince,
            zip: deliveryAddress.deliveryPostCode,
            country: 'South Africa',
            firstName: userDetails.fullName.split(' ')[0],
            lastName: userDetails.fullName.split(' ')[1],
            phone: userDetails.cellNumber,
          },
          billingAddress: {
            address1:
              billingAddress?.billingStreet ?? deliveryAddress.deliveryStreet,
            address2:
              billingAddress?.billingComplex ?? deliveryAddress.deliveryComplex,
            city: billingAddress?.billingCity ?? deliveryAddress.deliveryCity,
            province:
              billingAddress?.billingProvince ??
              deliveryAddress.deliveryProvince,
            zip:
              billingAddress?.billingPostCode ??
              deliveryAddress.deliveryPostCode,
            country: 'South Africa',
            firstName: userDetails.fullName.split(' ')[0],
            lastName: userDetails.fullName.split(' ')[1],
            phone: userDetails.cellNumber,
          },
          email: userDetails.email,
          note: '',
          appliedDiscount: {
            amount: cart.discountPrice ?? 0,
            description: 'Discount',
            title: `${cart.discountCode}`,
            value: cart.discountPrice ?? 0,
            valueType: 'FIXED_AMOUNT',
          },
          shippingLine: {
            title: 'Standard Shipping',
            price: cart.deliveryPrice || 0,
          },
        },
      };

      const data = {
        query: `mutation draftOrderCreate($input: DraftOrderInput!) {
          draftOrderCreate(input: $input) {
            userErrors {
              field
              message
            }
            draftOrder {
              id
              status
              invoiceUrl
              email
              totalPrice
              lineItems(first: 2) {
                edges {
                  node {
                    image {
                      transformedSrc
                    }
                  }
                }
              }
            }
          }
        }`,
        variables: orderDetails,
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      // Handle errors based on your application requirements
      console.error('Error creating draft order:', error);
      datadogLogs.logger.debug('Checkout error', {
        message: error,
      });
      throw error; // Rethrow the error if needed
    }
  };

  const calculateShopifyOrder = async (
    cart: CartType,
    userDetails: UserFormType,
    deliveryAddress: DeliveryFormType,
    billingAddress: BillingFormType
  ) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const orderDetails = {
        input: {
          customAttributes: { key: 'ikShopDirectOrder', value: 'true' },
          tags: ['IKHOKHA_HUBSPOT_CART_2_0'],
          lineItems: cart.lineItems.map((item) => ({
            variantId: item.product.variantGid,
            quantity: item.quantity,
          })),
          shippingAddress: {
            address1: deliveryAddress.deliveryStreet,
            city: deliveryAddress.deliveryCity,
            province: deliveryAddress.deliveryProvince,
            zip: deliveryAddress.deliveryPostCode,
            country: 'South Africa',
            firstName: userDetails.fullName.split(' ')[0],
            lastName: userDetails.fullName.split(' ')[1],
            phone: userDetails.cellNumber,
          },
          billingAddress: {
            address1:
              billingAddress?.billingStreet ?? deliveryAddress.deliveryStreet,
            city: billingAddress?.billingCity ?? deliveryAddress.deliveryCity,
            province:
              billingAddress?.billingProvince ??
              deliveryAddress.deliveryProvince,
            zip:
              billingAddress?.billingPostCode ??
              deliveryAddress.deliveryPostCode,
            country: 'South Africa',
            firstName: userDetails.fullName.split(' ')[0],
            lastName: userDetails.fullName.split(' ')[1],
            phone: userDetails.cellNumber,
          },
          email: userDetails.email,
          note: '',
          appliedDiscount: {
            amount: cart.discountPrice ?? 0,
            description: 'Discount',
            title: `${cart.discountCode}`,
            value: cart.discountPrice ?? 0,
            valueType: 'FIXED_AMOUNT',
          },
        },
      };

      const data = {
        query: `mutation draftOrderCalculate($input: DraftOrderInput!) {
          draftOrderCalculate(input: $input) {
            calculatedDraftOrder {
              subtotalPrice
              totalPrice
              totalShippingPrice
              totalTax
              availableShippingRates {
                  handle
                  title
                  price {
                      amount
                  }
              }
            }
            userErrors {
              field
              message
            }
          }
        }`,
        variables: orderDetails,
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();

      return json;
    } catch (error) {
      // Handle errors based on your application requirements
      console.log('Error calculating shopify order:', error);
      throw error; // Rethrow the error if needed
    }
  };

  const addNoteToDraftOrder = async (orderId: string) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const data = {
        query: `mutation draftOrderUpdate($id: ID!, $input: DraftOrderInput!) {
          draftOrderUpdate(id: $id, input: $input) {
            draftOrder {
              id
            }
            userErrors {
              field
              message
            }
          }
        }`,
        variables: {
          id: `${orderId}`,
          input: {
            note: `${orderId}`,
          },
        },
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();

      return json;
    } catch (error) {
      // Handle errors based on your application requirements
      console.error('Error updating shopify draft order:', error);
      throw error; // Rethrow the error if needed
    }
  };

  const updateShopifyOrderAmountById = async (
    orderId: string,
    deliveryAmount: number,
    deliveryHandle: string
  ) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const data = {
        query: `mutation draftOrderUpdate($id: ID!, $input: DraftOrderInput!) {
          draftOrderUpdate(id: $id, input: $input) {
            draftOrder {
              id
            }
            userErrors {
              field
              message
            }
          }
        }`,
        variables: {
          id: `${orderId}`,
          input: {
            shippingLine: {
              price: deliveryAmount,
              shippingRateHandle: deliveryHandle,
              title: 'Door to Door',
            },
            note: `${orderId}`,
          },
        },
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();

      return json;
    } catch (error) {
      // Handle errors based on your application requirements
      console.error('Error updating shopify draft order:', error);
      throw error; // Rethrow the error if needed
    }
  };
  const updateLineItems = async (lineItemUpdates: any[]) => {
    try {
      // Fetch the cart
      if (cartId === null) {
        return;
      }

      const lineItemsWithoutProduct = lineItemUpdates.map(
        ({ product, ...rest }) => rest
      );

      // Update the line items
      const updatedCart = await shopifyClient.checkout.updateLineItems(
        cartId,
        lineItemsWithoutProduct
      );

      return updateCart(lineItemUpdates, updatedCart);
    } catch (error) {
      console.error('Error updating cart:', error);
      throw error;
    }
  };

  const setShopifyOrderAsPaid = async (orderId: string) => {
    try {
      const proxyUrl = '/api/shopify';
      const headers = new Headers({
        'Content-Type': 'application/json',
      });

      const data = {
        query: `mutation draftOrderComplete($id: ID!) {
          draftOrderComplete(id: $id) {
            draftOrder {
              id
              status
              invoiceUrl
              email
              totalPrice
              lineItems(first: 2) {
                edges {
                  node {
                    image {
                      transformedSrc
                    }
                  }
                }
              }
            }
            userErrors {
              field
              message
            }
          }
        }`,
        variables: {
          id: `${orderId}`,
        },
      };

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const response = await fetch(proxyUrl, requestOptions);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Fetch error:', response.status, errorText);
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const json = await response.json();

      return json;
    } catch (error) {
      // Handle errors based on your application requirements
      console.error('Error marking order as paid:', error);
      throw error; // Rethrow the error if needed
    }
  };

  return {
    addItem,
    addItems,
    updateItem,
    removeItem,
    addDiscount,
    fetchCart,
    updateLineItems,
    updateShopifyShippingAddress,
    createShopifyOrder,
    calculateShopifyOrder,
    updateShopifyOrderAmountById,
    fetchShopifyDraftOrder,
    setShopifyOrderAsPaid,
    isRemovingOneItem,
    isAddingOneItem,
    isRemovingLineItem,
    addNoteToDraftOrder,
    productIsDebitCard,
    fetchLineItems,
  };
};

export default useCart;
